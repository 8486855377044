import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [];

// https://github.com/vuejs/vue-router
export default new VueRouter({
  routes, // short for routes: routes,
  mode: 'history',
  base: window.BASE_PATH || '/',
  fallback: false
});
