import vClickOutside from 'v-click-outside';

export default {
  name: 'MenuMyTaxDropdown',
  props: {},
  data() {
    return {
      visibleMenu: false
    };
  },
  methods: {
    toggleMenu() {
      return (this.visibleMenu = !this.visibleMenu);
    },
    isVisible() {
      return this.visibleMenu;
    },
    isVisibleStringForAriaExpanded() {
      return this.visibleMenu ? 'true' : 'false';
    },
    hideMenu() {
      this.visibleMenu = false;
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
};
