<template>
  <nav
    class="menu-language-dropdown"
    @keyup.27="hideLanguageMenu"
    v-click-outside="hideLanguageMenu"
    :aria-label="$trans('/accessibility/languagenavigation')"
  >
    <Trap :disabled="!isLanguageMenuVisible">
      <button
        class="menu-language-dropdown__button"
        v-bind:class="{
          'menu-language-dropdown__button--open': isLanguageMenuVisible
        }"
        aria-haspopup="true"
        id="menu-language-dropdown__button"
        aria-controls="menu-language-dropdown__panel"
        :lang="currentLanguage"
        :aria-expanded="isLanguageMenuVisible ? 'true' : 'false'"
        @click="toggleLanguageMenu()"
        data-cy="menu-language-toggle"
      >
        <icon v-if="showAlternateIcon" glyph="#icon-earth"></icon>
        <span
          class="menu-language-dropdown__name"
          v-if="!showOnlyLanguageCodeInToggle"
          :lang="currentLanguage"
          v-text="getCurrentLanguageDisplayName"
        />
        <span class="menu-language-dropdown__code" v-if="showOnlyLanguageCodeInToggle">{{
          currentLanguage
        }}</span>
      </button>
      <div
        class="menu-language-dropdown__panel"
        id="menu-language-dropdown__panel"
        v-show="isLanguageMenuVisible"
        :class="{ open: isLanguageMenuVisible }"
        aria-labelledby="menu-language-dropdown__button"
        role="region"
        v-cloak
      >
        <ul class="menu-language-dropdown__list" v-if="hasLanguages">
          <li v-for="language of languages" v-bind:key="language.DisplayName">
            <a
              class="menu-language-dropdown__link"
              :class="{ selected: language.Selected }"
              :href="language.Url"
              :lang="language.Name"
              data-cy="menu-language-link"
              v-html="language.DisplayName"
              ><span
                class="menu-language-dropdown__code"
                v-if="language.Name && language.DisplayName !== 'Other languages'"
              >
                ({{ language.Name }})</span
              ></a
            >
          </li>
        </ul>
      </div>
    </Trap>
  </nav>
</template>

<script>
import _isNil from 'lodash/isNil';
import Trap from 'vue-focus-lock';
import vClickOutside from 'v-click-outside';

export default {
  name: 'LanguageMenuDropdown',
  props: {
    languages: { type: Array, default: () => [] },
    showOnlyLanguageCodeInToggle: { type: Boolean, default: true },
    showOnlyLanguageNominativeInToggle: { type: Boolean, default: false },
    showAlternateIcon: { type: Boolean, default: false }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: { Trap },
  data() {
    return {
      isLanguageMenuVisible: false,
      currentLanguage: window.site_locale
    };
  },
  computed: {
    hasLanguages() {
      return !_isNil(this.languages) && this.languages.length > 0;
    },
    getCurrentLanguageDisplayName() {
      if (!this.showOnlyLanguageNominativeInToggle) {
        return this.languages.filter(x => x.Name === this.currentLanguage)[0].DisplayName;
      }
      switch (this.currentLanguage) {
        case 'fi':
          return this.showAlternateIcon ? 'Suomeksi (FI)' : 'Suomi';
        case 'sv':
          return this.showAlternateIcon ? 'På svenska (SV)' : 'Svenska';
        case 'en':
          return this.showAlternateIcon ? 'In English (EN)' : 'English';
        default:
          return this.showAlternateIcon ? 'Suomeksi (FI)' : 'Suomi';
      }
    }
  },
  methods: {
    toggleLanguageMenu() {
      this.isLanguageMenuVisible = !this.isLanguageMenuVisible;
    },
    hideLanguageMenu() {
      this.isLanguageMenuVisible = false;
    }
  }
};
</script>
