import Icon from 'shared/components/Icon';
import BackToTop from 'shared/components/BackToTop';
import AnchorLink from 'shared/components/AnchorLink';
import LanguageMenuDropdown from 'shared/components/navigation/LanguageMenuDropdown';
import MegaMenu from 'shared/components/inline/MegaMenu';
import MobileMenu from 'shared/components/navigation/MobileMenu';
import SearchSuggestions from 'shared/components/search/SearchSuggestions';
import Feedback from 'shared/components/Feedback';
import MenuMyTaxDropdown from './components/inline/MenuMyTaxDropdown';
import HeaderMobileVeroFi from './components/inline/HeaderMobileVeroFi';
import MyTaxMobileHeader from './components/mobile-navigation/MyTaxMobileHeader';
import MobileMenuMyTax from './components/mobile-navigation/MobileMenuMyTax';
import MobileMenuLanguage from './components/mobile-navigation/MobileMenuLanguage';
import MobileMenuNavigation from './components/mobile-navigation/MobileMenuNavigation';
import MobileMenuMyTaxLogo from './components/mobile-navigation/MobileMenuMyTaxLogo';

// Code splitted
const Accordion = () => import(/* webpackChunkName: "accordion" */ './components/Accordion');
const ResponsiveIframe = () => import(/* webpackChunkName: "responsive_iframe" */ 'shared/components/ResponsiveIframe');
const FeedbackReport = () => import(/* webpackChunkName: "feedback-report" */ './components/FeedbackReport');
const AlcoholTaxCalculator = () => import(/* webpackChunkName: "alcohol-tax-calculator" */ './components/calculators/AlcoholTaxCalculator');
const AssetTransferTaxCalculator = () => import(/* webpackChunkName: "asset-transfer-tax-calculator" */ './components/calculators/AssetTransferTaxCalculator');
const GiftTaxCalculator = () => import(/* webpackChunkName: "gift-tax-calculator" */ './components/calculators/GiftTaxCalculator');
const InheritanceTaxCalculator = () => import(/* webpackChunkName: "inheritance-tax-calculator" */ './components/calculators/InheritanceTaxCalculator');
const VatLowerLimitCalculator = () => import(/* webpackChunkName: "vat-lower-limit-calculator" */ './components/calculators/VatLowerLimitCalculator');
const InterestCalculator = () => import(/* webpackChunkName: "interest-calculator" */ './components/calculators/InterestCalculator');
const AnnualInformationCalculator = () => import(/* webpackChunkName: "annual-information-calculator" */ './components/calculators/AnnualInformationCalculator');
const VatCalculator = () => import(/* webpackChunkName: "vat-calculator" */ './components/calculators/VatCalculator');
const ImportantDates = () => import(/* webpackChunkName: "important-dates" */ './components/ImportantDates');
const ImportantDateCard = () => import(/* webpackChunkName: "important-date-card" */ './components/ImportantDateCard');
const Charts = () => import(/* webpackChunkName: "charts" */ './components/charts/Charts');
const Wizard = () => import(/* webpackChunkName: "wizard" */ 'shared/components/wizard/Wizard');
const WizardOld = () => import(/* webpackChunkName: "wizardOld" */ 'shared/components/WizardOld');
const UserGuideList = () => import(/* webpackChunkName: "userguidelist" */ './components/inline/UserGuideList');
const TableOfContents = () => import(/* webpackChunkName: "table-of-contents" */ 'shared/components/table-of-contents/TableOfContents');
const TableOfContentsMobile = () => import(/* webpackChunkName: "table-of-contents" */ 'shared/components/table-of-contents/TableOfContentsMobile');
const Search = () => import(/* webpackChunkName: "search" */ 'shared/components/search/Search');
const TaxOfficeSearch = () => import(/* webpackChunkName: "tax-office-search" */ './components/TaxOfficeSearch');
const FormOrderReport = () => import(/* webpackChunkName: "form-order-report" */ './components/FormOrderReport');
const Twitter = () => import(/* webpackChunkName: "twitter" */ 'shared/components/Twitter');
const DataTable = () => import(/* webpackChunkName: "datatable" */ 'shared/components/data-table/DataTable');
const HouseholdExpenseCalculator = () => import(/* webpackChunkName: "household-expense-calcuator" */ './components/calculators/HouseholdExpenseCalculator');
const HouseholdExpenseCalculator2022 = () => import(/* webpackChunkName: "household-expense-calcuator-2022" */ './components/calculators/HouseholdExpenseCalculator2022');
const ElectricityDeductionCalculator = () => import(/* webpackChunkName: "electricity-deduction-calculator" */ './components/calculators/ElectricityDeductionCalculator');
const Tabs = () => import(/* webpackChunkName: "tabs" */ 'shared/components//Tabs');
const Tab = () => import(/* webpackChunkName: "tab" */ 'shared/components//Tab');

export {
  LanguageMenuDropdown,
  MegaMenu,
  MobileMenu,
  HeaderMobileVeroFi,
  MyTaxMobileHeader,
  MobileMenuMyTaxLogo,
  MobileMenuNavigation,
  MobileMenuLanguage,
  MobileMenuMyTax,
  SearchSuggestions,
  Feedback,
  FeedbackReport,
  ImportantDates,
  ImportantDateCard,
  Icon,
  ResponsiveIframe,
  Accordion,
  AssetTransferTaxCalculator,
  GiftTaxCalculator,
  InheritanceTaxCalculator,
  VatLowerLimitCalculator,
  AnnualInformationCalculator,
  VatCalculator,
  InterestCalculator,
  Charts,
  AlcoholTaxCalculator,
  Wizard,
  WizardOld,
  UserGuideList,
  MenuMyTaxDropdown,
  TableOfContents,
  TableOfContentsMobile,
  BackToTop,
  AnchorLink,
  Search,
  FormOrderReport,
  Twitter,
  TaxOfficeSearch,
  DataTable,
  HouseholdExpenseCalculator,
  HouseholdExpenseCalculator2022,
  ElectricityDeductionCalculator,
  Tabs,
  Tab
};
