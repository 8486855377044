import Vue from 'vue';
import VueEvents from 'vue-events';
import _kebabCase from 'lodash/kebabCase';
import 'src/sites/tax_fi/sass/styles.scss';
import { MediaQueries } from 'vue-media-queries';
import router from 'router/router';
import translate from 'utils/translation';
import * as components from './components';
import * as directives from './directives';
import epiforms from 'src/epiforms/epiform.custom.validate';

// Custom polyfills and migrated accordions
import polyfill from 'utils/customPolyfills';
import enableMigratedAccordions from 'utils/migratedAccordions';
polyfill();

Vue.prototype.$trans = translate;

const mediaQueries = new MediaQueries();

Vue.use(mediaQueries);

// https://github.com/cklmercer/vue-events
Vue.use(VueEvents);

Object.keys(components).forEach(key => {
  Vue.component(_kebabCase(key), components[key]);
});

Object.keys(directives).forEach(key => {
  Vue.directive(_kebabCase(key), directives[key]);
});

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    router,
    mediaQueries
  });
  enableMigratedAccordions();
});

// Add epiforms validation to window
window.epiforms = epiforms;
