<template>
  <Trap :disabled="!showNewLayout ? !isVisible : !parentIsVisible">
    <button
      class="search-button"
      @click="toggleVisible"
      :aria-expanded="isVisible ? 'true' : 'false'"
      aria-haspopup="true"
      id="search-overlay-button"
      aria-controls="search-overlay"
      :aria-label="getAriaLabelForButton"
      data-cy="search-overlay-toggle"
    >
      <span class="search-button__wrapper" v-show="!showNewLayout">
        <span class="search-button__text">{{ getButtonText }}</span>
        <icon :glyph="getGlyph"></icon>
      </span>
      <span class="search-button__wrapper" v-show="showNewLayout">
        <icon :glyph="getGlyph"></icon>
        <span class="search-button__text">{{ getButtonText }}</span>
      </span>
    </button>
    <div
      class="search-overlay"
      v-show="!showNewLayout ? isVisible : parentIsVisible"
      id="search-overlay"
      role="region"
      @keyup.27="toggleVisible"
      @click.self="toggleVisible"
      data-cy="search-overlay"
      v-cloak
    >
      <div class="container">
        <button
          class="search-button search-button-overlay"
          @click="toggleVisible"
          :aria-expanded="isVisible ? 'true' : 'false'"
          aria-controls="search-overlay"
          :aria-label="getAriaLabelForButton"
          data-cy="search-overlay-toggle"
          v-if="showButtonInOverlay"
        >
          <span class="search-button__wrapper">
            <span class="search-button__text">
              {{ this.$trans('/common/close') }}
            </span>
            <icon glyph="#icon-close-v2"></icon>
          </span>
        </button>
        <div class="row">
          <div class="search-overlay__search">
            <div
              v-if="showNewLayout && !isDesktop"
              for="search-field"
              data-cy="search-field-label"
              class="search-field__label"
              :placeholder="$trans('/common/searchfromsite')"
              :aria-label="$trans('/common/searchfromsite')"
            >
              {{ $trans('/common/searchfromsite') }}
            </div>
            <search-field
              id="menu_search"
              name="search_form2"
              :on-submit="onSubmit"
              :on-key-up-enabled="true"
              :on-key-up="onKeyUp"
              :page-name="pageName"
              :show-label="showInputLabel"
              :show-alternate-icon="showNewLayout"
              component-location="Ennakoiva_haku"
              :tracking-disabled="trackingDisabled"
              ref="searchfield"
            />
            <div
              class="search-overlay__autocomplete"
              v-if="results.length > 0"
              aria-label="results"
            >
              <div v-for="item in results" :key="item.id">
                <h3>
                  <a :href="item.Url">{{ item.Title }}</a>
                </h3>
                <p v-html="item.Text"></p>
              </div>
              <a
                :href="getSearchPageLinkWithQuery"
                class="btn"
                v-if="results.length >= this.minChars"
              >
                {{ $trans('search/showmoreresults') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Trap>
</template>
<script>
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import SearchApi from 'api/SearchApi';
import Trap from 'vue-focus-lock';
import SearchField from './SearchField.vue';

export default {
  name: 'SearchSuggestions',
  props: {
    language: { type: String, default: 'fi' },
    rootFilterIds: { type: Array },
    searchPageUrl: { type: String },
    count: { type: Number },
    minChars: { type: Number, default: 2 },
    isDesktop: { type: Boolean, default: false },
    showNewLayout: { type: Boolean, default: false },
    searchSuggestionsApiUrl: { type: String, default: '' },
    setVisibleMenu: { type: Function },
    parentIsVisible: { type: Boolean, default: false },
    pageName: { type: String, default: '' },
    trackingDisabled: { type: Boolean, default: false },
    showInputLabel: { type: Boolean, default: false }
  },
  components: { SearchField, Trap },
  data() {
    return {
      results: {},
      query: '',
      loading: false,
      isComponentVisible: false
    };
  },
  computed: {
    getSearchPageLinkWithQuery() {
      return `${this.searchPageUrl}?query=${this.query}`;
    },
    getAriaLabelForButton() {
      return this.isVisible
        ? `${this.$trans('/common/close')} ${this.$trans('/common/search')}`
        : undefined;
    },
    getButtonText() {
      if (this.showNewLayout || !this.isDesktop) {
        return this.$trans('/common/dosearch');
      }
      return this.isVisible ? this.$trans('/common/close') : this.$trans('/common/dosearch');
    },
    getGlyph() {
      const iconSearch = !this.showNewLayout ? '#icon-search' : '#icon-search-v2';
      if (this.showNewLayout || !this.isDesktop) {
        return iconSearch;
      }
      return this.isVisible ? '#icon-close' : iconSearch;
    },
    isVisible() {
      return this.isDesktop ? this.parentIsVisible : this.isComponentVisible;
    },
    showButtonInOverlay() {
      return this.showNewLayout && this.isDesktop ? true : false;
    }
  },
  methods: {
    // Run when text is written on input
    onKeyUp: _debounce(function (inputQuery) {
      // Query gets value from input value
      this.query = inputQuery;

      // Check if empty / enough characters
      if (_isEmpty(this.query) || this.query.length < this.minChars) {
        this.results = [];
        return;
      }

      // Make the search
      this.search();
    }, 300),
    // Get results from the search api
    search() {
      this.loading = true;
      SearchApi.getSearchSuggestions(
        this.searchSuggestionsApiUrl,
        this.query,
        this.language,
        this.rootFilterIds,
        this.count
      ).then(response => {
        this.results = response;
        this.loading = false;
      });
    },
    toggleVisible() {
      if (this.isDesktop || this.showNewLayout) {
        this.setVisibleMenu(0);
      }
      this.isComponentVisible = !this.isComponentVisible;

      this.$nextTick(() => {
        if (this.isVisible || this.parentIsVisible) {
          this.$refs.searchfield.$refs.query.focus();
        }
      });
    },
    onSubmit(inputQuery) {
      this.query = inputQuery || '';
      window.location = this.getSearchPageLinkWithQuery;
    }
  }
};
</script>
