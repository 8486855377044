import axios from 'axios';

axios.defaults.xsrfCookieName = '__RequestVerificationToken';

export default {
  get(url, request) {
    return axios.get(url, request).then(({ data }) => data);
  },
  post(url, request, config = null) {
    if (config) {
      return axios.post(url, request, config).then(({ data }) => data);
    }
    return axios.post(url, request).then(({ data }) => data);
  },
  put(url, request, config = null) {
    if (config) {
      return axios.put(url, request, config).then(({ data }) => data);
    }
    return axios.put(url, request).then(({ data }) => data);
  },
  delete(url, request, config = null) {
    if (config) {
      return axios.delete(url, request, config).then(({ data }) => data);
    }
    return axios.delete(url, request).then(({ data }) => data);
  }
};
