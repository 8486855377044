<script>
const sendPiwikEvent = (el, event) => {
  return new Promise(function (resolve, reject) {
    try {
      const piwikEvent = {
        category: el.getAttribute('data-piwik-event-name'),
        action: event.target.innerText,
        name: event.target.href
      };
      _paq.push(['trackEvent', piwikEvent.category, piwikEvent.action, piwikEvent.name]);
      resolve('Piwik event sent!');
    } catch {
      reject('Error sending Piwik event');
    }
  });
};

export default {
  bind(el) {
    el.addEventListener('click', event => {
      if (event) {
        event.preventDefault();
      }
      const isLink = event.target.tagName.toLowerCase() === 'a';
      if (!isLink) {
        return;
      }
      const link = event.target.href;
      sendPiwikEvent(el, event)
        .then(msg => {
          window.location = link;
        })
        .catch(msg => {
          window.location = link;
        });
    });
  }
};
</script>
