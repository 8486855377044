export default {
  name: 'MobileMenuVeroFi',
  data() {
    return {
      closeButtonVisible: false,
      visibleMenuId: -1
    };
  },
  methods: {
    isVisibleMenu(menuId) {
      return menuId === this.visibleMenuId;
    },
    setVisibleMenu(menuId) {
      this.visibleMenuId = this.getVisibleMenuId(menuId);
    },
    getVisibleMenuId(menuId) {
      var result = this.visibleMenuId === menuId ? -1 : menuId;
      this.closeButtonVisible = result === -1 ? false : true;
      return result;
    },
    showCloseButton() {
      return this.closeButtonVisible;
    }
  }
};
