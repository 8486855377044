<template>
  <div v-cloak>
    <button
      class="mobile-button menu-button hamburger"
      id="mobile-menu-button"
      aria-controls="mobile-menu"
      :aria-label="$trans('/accessibility/openmenumobile')"
      aria-haspopup="true"
      :aria-expanded="isMobileMenuVisible ? 'true' : 'false'"
      @click="toggleMobileMenu()"
      ref="toggle"
    >
      <span class="hamburger-box">
        <icon v-if="showAlternateIcon" glyph="#icon-menu"></icon>
        <span v-if="!showAlternateIcon" class="hamburger-inner"></span>
      </span>
      {{ $trans('/common/menu') }}
    </button>
    <Trap :disabled="!isMobileMenuVisible">
      <div
        class="panel-offcanvas"
        role="region"
        aria-labelledby="mobile-menu-button"
        id="mobile-menu"
        ref="mobilemenu_wrapper"
        v-if="isMobileMenuVisible"
        v-cloak
        @keyup.27="toggleMobileMenu"
      >
        <div class="panel-offcanvas__top">
          <button
            class="panel-offcanvas__close"
            id="panel-close"
            :aria-label="`${$trans('/common/close')} ${$trans('/accessibility/menumobile')}`"
            @click="toggleMobileMenu()"
          >
            <span>{{ $trans('/common/close') }}</span>
            <icon :glyph="getGlyph"></icon>
          </button>
          <a :href="viewModel.RootPageUrl" class="panel-offcanvas__logo">
            <img :src="logoUrl" :alt="$trans('/accessibility/tofrontpage')" />
          </a>
          <div class="panel-offcanvas__logo secondary" v-if="logoUrl2">
            <img :src="logoUrl2" :alt="$trans('/accessibility/tofrontpage')" />
          </div>
          <slot />
        </div>
        <nav class="menu-mobile" ref="mobilemenu" tabindex="-1">
          <div class="menu-mobile__loader" v-if="isLoading">
            <div class="loader"></div>
            <span>{{ $trans('/common/menuloading') }}</span>
          </div>
          <p v-show="showError" v-cloak>{{ $trans('/common/menuerror') }}</p>
          <div v-show="!isLoading" v-cloak>
            <div class="menu-mobile__crumbs" v-if="getCrumbsVisibility()">
              <span>{{ MobileMenuLinks.ParentName }}</span>
            </div>
            <div class="menu-mobile__panels">
              <div class="menu-panel">
                <button
                  v-if="MobileMenuLinks.ParentId !== null"
                  class="prev"
                  @click="move(MobileMenuLinks.ParentId)"
                  :aria-label="$trans('/accessibility/previouslevel')"
                ></button>
                <ul v-if="MobileMenuLinks.Links && MobileMenuLinks.Links.length">
                  <li
                    v-for="link of MobileMenuLinks.Links"
                    v-bind:class="{ selected: isLinkSelected(link.Id) }"
                    :key="link.Id"
                  >
                    <a
                      :href="link.Url"
                      v-bind:class="{ haschildren: link.HasChildren }"
                      :aria-selected="isLinkSelected(link.Id)"
                      >{{ link.Name }}</a
                    >
                    <button
                      v-if="link.HasChildren"
                      class="next"
                      @click="move(link.Id)"
                      :aria-label="$trans('/accessibility/nextlevel')"
                    ></button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </Trap>
  </div>
</template>

<script>
import _find from 'lodash/find';
import _first from 'lodash/first';
import _isNil from 'lodash/isNil';
import MobileMenuApi from 'api/MobileMenuApi';
import Trap from 'vue-focus-lock';

export default {
  name: 'MobileMenu',
  components: { Trap },
  props: {
    viewModel: { type: Object, default: () => {} },
    logoUrl: { type: String, default: '' },
    logoUrl2: { type: String, default: '' },
    showAlternateIcon: { type: Boolean, default: false }
  },
  data() {
    return {
      results: [],
      MobileMenuLinks: [],
      errors: [],
      language: window.site_locale,
      isLoading: false,
      showError: false,
      isMobileMenuVisible: false,
      isMenuCreated: false
    };
  },
  computed: {
    getGlyph() {
      const iconClose = !this.showAlternateIcon ? '#icon-close' : '#icon-close-v2';
      return this.isVisible ? '#icon-close' : iconClose;
    }
  },
  methods: {
    toggleMobileMenu() {
      if (!this.isMenuCreated) {
        this.createMobileMenu();
      }
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
      this.$events.emit('transformEvent', this.isMobileMenuVisible);
      if (!this.isMobileMenuVisible) {
        this.addFocus(this.$refs.toggle);
      }
    },
    getVisibleMenu(panelId = this.viewModel.ParentPageId) {
      const visibleMenu = _find(this.results, { Id: panelId });
      return !_isNil(visibleMenu) ? visibleMenu : _first(this.results);
    },
    isLinkSelected(panelId) {
      return panelId === this.viewModel.CurrentPageId;
    },
    getCrumbsVisibility() {
      return !_isNil(this.MobileMenuLinks.ParentName);
    },
    move(targetId) {
      this.MobileMenuLinks = this.getVisibleMenu(targetId);
      this.addFocus(this.$refs.mobilemenu);
    },
    createMobileMenu() {
      this.isLoading = true;

      MobileMenuApi.getMobileMenuItems(
        this.viewModel.MobileMenuApiUrl,
        this.viewModel.RootPageId,
        this.language
      )
        .then(response => {
          this.isLoading = false;
          this.results = response;
          this.MobileMenuLinks = this.getVisibleMenu();
          this.isMenuCreated = true;
        })
        .catch(() => {
          this.isLoading = false;
          this.showError = true;
        });
    },
    addFocus(el) {
      if (el) {
        this.$nextTick(() => el.focus());
      }
    }
  }
};
</script>
