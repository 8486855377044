<template>
  <Trap :disabled="!parentIsVisible">
    <div v-cloak>
      <button
        class="mobile-button menu-button hamburger"
        id="mobile-menu-button"
        aria-controls="mobile-menu"
        :aria-label="getButtonAriaLabelText"
        aria-haspopup="true"
        :aria-expanded="showCloseButton ? 'true' : 'false'"
        @click="toggleMobileMenu()"
        @keyup.27="toggleMobileMenu"
        ref="toggle"
      >
        <span class="hamburger-box">
          <icon :glyph="getGlyph"></icon>
        </span>
        {{ getButtonText }}
      </button>
      <div :disabled="!parentIsVisible">
        <div
          class="mobile-menu-navigation--panel"
          role="region"
          aria-labelledby="mobile-menu-button"
          id="mobile-menu"
          ref="mobilemenu_wrapper"
          v-if="parentIsVisible"
          v-cloak
          @click.self="toggleMobileMenu"
          @keyup.27="toggleMobileMenu"
        >
          <nav class="mobile-menu-navigation container" ref="mobilemenu" tabindex="-1">
            <div class="mobile-menu-navigation__loader" v-if="isLoading">
              <span>
                <div class="loader"></div>
                {{ $trans('/common/menuloading') }}
              </span>
            </div>
            <p v-show="showError" class="show-error" v-cloak>{{ $trans('/common/menuerror') }}</p>
            <div v-show="!isLoading" v-cloak>
              <div class="mobile-menu-navigation__crumbs" v-if="getCrumbsVisibility()">
                <span>{{ MobileMenuLinks.ParentName }}</span>
              </div>
              <div class="mobile-menu-navigation__panels">
                <div class="menu-panel">
                  <button
                    v-if="MobileMenuLinks.ParentId !== null && !showError"
                    class="prev"
                    @click="move(MobileMenuLinks.ParentId)"
                    :aria-label="$trans('/accessibility/previouslevel')"
                  ></button>
                  <ul v-if="MobileMenuLinks.Links && MobileMenuLinks.Links.length">
                    <li
                      v-for="link of MobileMenuLinks.Links"
                      v-bind:class="{ selected: isLinkSelected(link.Id) }"
                      :key="link.Id"
                    >
                      <a
                        :href="link.Url"
                        v-bind:class="{ haschildren: link.HasChildren }"
                        :aria-selected="isLinkSelected(link.Id)"
                        >{{ link.Name }}</a
                      >
                      <button
                        v-if="link.HasChildren"
                        class="next"
                        @click="move(link.Id)"
                        :aria-label="$trans('/accessibility/nextlevel')"
                      ></button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </Trap>
</template>

<script>
import _find from 'lodash/find';
import _first from 'lodash/first';
import _isNil from 'lodash/isNil';
import MobileMenuApi from 'api/MobileMenuApi';
import Trap from 'vue-focus-lock';

export default {
  name: 'MobileNavigation',
  components: { Trap },
  props: {
    viewModel: { type: Object, default: () => {} },
    setVisibleMenu: { type: Function },
    parentIsVisible: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: false }
  },
  data() {
    return {
      results: [],
      MobileMenuLinks: [],
      errors: [],
      language: window.site_locale,
      isLoading: false,
      showError: false,
      isMobileMenuVisible: false,
      isMenuCreated: false
    };
  },
  computed: {
    getGlyph() {
      return this.showCloseButton ? '#icon-menu-close' : '#icon-menu';
    },
    getButtonText() {
      return this.showCloseButton ? this.$trans('/common/close') : this.$trans('/common/menu');
    },
    getButtonAriaLabelText() {
      return this.showCloseButton
        ? this.$trans('/accessibility/close')
        : this.$trans('/accessibility/openmenumobile');
    }
  },
  methods: {
    toggleMobileMenu() {
      if (!this.isMenuCreated) {
        this.createMobileMenu();
      }

      if (!this.parentIsVisible && this.showCloseButton) {
        this.setVisibleMenu(-1);
      } else {
        this.setVisibleMenu(1);
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
      }

      if (!this.isMobileMenuVisible) {
        this.addFocus(this.$refs.toggle);
      }
    },
    getVisibleMenu(panelId = this.viewModel.ParentPageId) {
      const visibleMenu = _find(this.results, { Id: panelId });
      return !_isNil(visibleMenu) ? visibleMenu : _first(this.results);
    },
    isLinkSelected(panelId) {
      return panelId === this.viewModel.CurrentPageId;
    },
    getCrumbsVisibility() {
      return !_isNil(this.MobileMenuLinks.ParentName);
    },
    move(targetId) {
      this.MobileMenuLinks = this.getVisibleMenu(targetId);
      this.addFocus(this.$refs.mobilemenu);
    },
    createMobileMenu() {
      this.isLoading = true;

      MobileMenuApi.getMobileMenuItems(
        this.viewModel.MobileMenuApiUrl,
        this.viewModel.RootPageId,
        this.language
      )
        .then(response => {
          this.isLoading = false;
          this.results = response;
          this.MobileMenuLinks = this.getVisibleMenu();
          this.isMenuCreated = true;
        })
        .catch(() => {
          this.isLoading = false;
          this.showError = true;
        });
    },
    addFocus(el) {
      if (el) {
        this.$nextTick(() => el.focus());
      }
    }
  }
};
</script>
