import vClickOutside from 'v-click-outside';

export default {
  name: 'MegaMenu',
  props: {},
  data() {
    return {
      visibleMenuId: -1 // Search overlay = 0
    };
  },
  methods: {
    isVisibleMenu(menuId) {
      return menuId === this.visibleMenuId;
    },
    setVisibleMenu(menuId) {
      // If menu is already visible, hide it, otherwise show it
      this.visibleMenuId = this.getVisibleMenuId(menuId);
      if (this.getVisibleMenuId(menuId) !== -1) {
        document.getElementById('menu_main_overlay').style.display = 'none';
      } else {
        document.getElementById('menu_main_overlay').style.display = 'block';
      }
    },
    getVisibleMenuId(menuId) {
      return this.visibleMenuId === menuId ? -1 : menuId;
    },
    hideMenu() {
      if (this.visibleMenuId === -1) return;

      this.visibleMenuId = -1;
      if (document.getElementById('menu_main_overlay')) {
        document.getElementById('menu_main_overlay').style.display = 'none';
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
};
