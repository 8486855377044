<template>
  <form
    @submit.prevent="onSubmit(query)"
    method="GET"
    class="search-field"
    data-cy="search-field"
    :id="id"
    :name="name"
    v-piwik-search-form="{ trackingDisabled: trackingDisabled }"
  >
    <div
      v-if="showLabel"
      for="search-field"
      data-cy="search-field-label"
      class="search-field__label"
      :placeholder="$trans('/common/searchfromsite')"
      :aria-label="$trans('/common/searchfromsite')"
    >
      {{ $trans('/common/searchfromsite') }}
    </div>
    <input
      type="text"
      class="search-field__input"
      :placeholder="!showAlternateIcon ? $trans('/common/writesearchword') : ''"
      :aria-label="$trans('/common/writesearchword')"
      v-model="query"
      ref="query"
      @input="onSearch"
      :data-piwik-event-category="`${componentLocation}_hakulaatikko`"
      :data-piwik-event-action="pageName"
      data-cy="searchField"
    />
    <button
      type="submit"
      :aria-label="$trans('/accessibility/initsearch')"
      class="search-field__button"
      :data-piwik-event-category="`${componentLocation}_hakunappi`"
      :data-piwik-event-action="pageName"
      data-cy="searchFieldSubmit"
    >
      <icon :glyph="getGlyph"></icon>
    </button>
  </form>
</template>

<script>
export default {
  name: 'SearchField',
  props: {
    queryString: { type: String, default: null },
    onSubmit: { type: Function, default: () => {} },
    onKeyUpEnabled: { type: Boolean, default: false },
    onKeyUp: { type: Function, default: () => {} },
    pageName: { type: String, default: '' },
    showAlternateIcon: { type: Boolean, default: false },
    componentLocation: { type: String, default: '' },
    trackingDisabled: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: false },
    id: { type: String, default: 'search_field' },
    name: { type: String, default: 'searchfield' }
  },
  data() {
    return {
      query: null
    };
  },
  mounted() {
    this.query = this.queryString;
  },
  methods: {
    onSearch() {
      if (this.onKeyUpEnabled) {
        this.onKeyUp(this.query);
      }
    }
  },
  computed: {
    getGlyph() {
      return !this.showAlternateIcon ? '#icon-search' : '#icon-search-v2';
    }
  }
};
</script>
