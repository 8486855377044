<template>
  <anchor-link
    href="#top"
    :css-class="`${getCssClass} back-to-top hidden-print`"
    :aria-label="$trans('/common/backtotop')"
    glyph="#icon-arrow-up"
  >
  </anchor-link>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {},
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    getCssClass() {
      return this.isVisible ? 'sticky' : '';
    }
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 100 && !this.isVisible) {
        this.isVisible = true;
      }
      if (window.pageYOffset <= 100 && this.isVisible) {
        this.isVisible = false;
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
