import API from 'utils/api';

/**
 *
 * @export
 * @param {string } mobileMenuApiUrl - api url
 * @param {int} id  - id
 * @param {string } language  - language
 * @param {string} type  - type
 * @returns
 */
export function getMobileMenuItems(mobileMenuApiUrl, id, language, type) {
  return API.get(mobileMenuApiUrl, {
    params: {
      id,
      language,
      type
    }
  });
}

export default {
  getMobileMenuItems
};
