<script>
const sendPiwikEvent = el => {
  return new Promise(function (resolve, reject) {
    try {
      const piwikEvent = {
        category: el.getAttribute('data-piwik-event-name'),
        action: el.innerText,
        name: el.href
      };
      _paq.push(['trackEvent', piwikEvent.category, piwikEvent.action, piwikEvent.name]);
      resolve(el, 'Piwik event sent!');
    } catch {
      reject(el, 'Error sending Piwik event');
    }
  });
};

export default {
  bind(el) {
    el.addEventListener('click', event => {
      if (event) {
        event.preventDefault();
      }
      const link = el.href;
      sendPiwikEvent(el)
        .then((el, msg) => {
          if (el.hasAttribute('aria-haspopup')) {
            return;
          }
          window.location = link;
        })
        .catch((el, msg) => {
          if (el.hasAttribute('aria-haspopup')) {
            return;
          }
          window.location = link;
        });
    });
  }
};
</script>
