function format(string, args) {
  return string.replace(/\{(\d+)\}/g, function (m, n) {
    return args[n];
  });
}

export default function translate(translationKey, ...args) {
  if (window.i18n === undefined) {
    return translationKey;
  }

  const translationString = window.i18n[translationKey];

  if (translationString === undefined || translationString === null) {
    return translationKey;
  }
  return format(translationString, args);
}
