<template>
  <Trap :disabled="!parentIsVisible">
    <div
      class="mobile-menu-my-tax"
      :aria-label="this.$trans('/accessibility/MyTaxMenu')"
      @keyup.27="toggleVisible"
      data-cy="menu-my-tax"
    >
      <button
        class="mobile-menu-my-tax__button"
        id="mobile-menu-my-tax-button"
        @click="toggleVisible"
        :aria-expanded="parentIsVisible ? 'true' : 'false'"
        aria-haspopup="true"
        aria-controls="menu-my-tax-overlay"
        :aria-label="getAriaLabelForMyTaxButton"
        data-cy="menu-my-tax-toggle"
      >
        <icon glyph="#icon-lock-v2"></icon>
        <span class="my-tax-button__text">
          {{ this.$trans('/common/mytax') }}
        </span>
      </button>
      <div
        class="mobile-menu-my-tax--overlay"
        v-show="parentIsVisible"
        id="mobile-menu-my-tax-overlay"
        role="region"
        @click.self="toggleVisible"
        @keyup.27="toggleVisible"
        data-cy="menu-my-tax-overlay"
        v-cloak
      >
        <div class="container">
          <div class="row">
            <div class="mobile-menu-my-tax--overlay__wrapper">
              <ul class="mobile-menu-my-tax__list">
                <li class="mobile-menu-my-tax__list-item">
                  {{ this.$trans('/mytax/InstructionText') }}
                </li>
                <li class="mobile-menu-my-tax__list-item">
                  <a
                    class="btn-action-small btn-icon"
                    :href="myTaxUrl"
                    :aria-label="this.$trans('/mytax/ButtonText')"
                    v-if="myTaxUrl !== ''"
                  >
                    {{ this.$trans('/mytax/ButtonText') }}
                    <svg
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      class="icon icon-arrow-forward"
                    >
                      <use xlink:href="#icon-arrow-forward"></use>
                    </svg>
                  </a>
                </li>
                <li class="mobile-menu-my-tax__list-item">
                  <a
                    :href="instructionUrl"
                    :aria-label="this.$trans('/mytax/InstructionLinkText')"
                    v-if="instructionUrl !== ''"
                  >
                    {{ this.$trans('/mytax/InstructionLinkText') }}
                  </a>
                </li>
              </ul>
              <div class="mobile-menu-my-tax__notification" v-if="noticeContentArea !== ''">
                <div v-html="noticeContentArea"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Trap>
</template>
<script>
import Trap from 'vue-focus-lock';
export default {
  name: 'MobileMyTaxMenu',
  components: { Trap },
  props: {
    setVisibleMenu: { type: Function },
    parentIsVisible: { type: Boolean, default: false },
    instructionUrl: { type: String, default: '#' },
    myTaxUrl: { type: String, default: '#' },
    noticeContentArea: { type: String, default: '' }
  },
  computed: {
    getAriaLabelForMyTaxButton() {
      return this.parentIsVisible ? this.$trans('/common/close') : this.$trans('/common/mytax');
    }
  },
  methods: {
    toggleVisible() {
      this.setVisibleMenu(3);
    }
  }
};
</script>
