<script>
const sendPiwikEvent = (el, event) => {
  return new Promise(function (resolve, reject) {
    try {
      const piwikEvent = {
        category: event.target.getAttribute('data-piwik-event-category'),
        action: event.target.getAttribute('data-piwik-event-action'),
        name: window.location.href
      };
      const isSubmitButton = event.target.tagName.toLowerCase() === 'button';
      const searchField = event.target.previousElementSibling;
      const searchLocation = `${piwikEvent.category}_${piwikEvent.action}`;

      _paq.push(['trackEvent', piwikEvent.category, piwikEvent.action, piwikEvent.name]);
      if (!isSubmitButton || searchField === null) {
        resolve('Piwik click event sent!');
        return;
      }
      _paq.push(['trackSiteSearch', searchField.value, searchLocation, false]);
      resolve('Piwik search event sent!');
    } catch {
      reject('Error sending Piwik event');
    }
  });
};

export default {
  bind(el, binding) {
    const trackingDisabled = binding.value?.trackingDisabled ?? false;
    if (trackingDisabled) {
      return;
    }
    el.addEventListener('click', event => {
      const isSearchFormElement =
        event.target.tagName.toLowerCase() === 'input' ||
        event.target.tagName.toLowerCase() === 'button';
      if (!isSearchFormElement) {
        return;
      }
      sendPiwikEvent(el, event)
        .then(msg => {
          // success
        })
        .catch(msg => {
          // Error
        });
    });
  }
};
</script>
