<template>
  <div class="my-tax-mobile-header">
    <ul class="my-tax-mobile-header__list">
      <li class="my-tax-mobile-header__list-item">
        <div class="my-tax-mobile-header__logo">
          <div class="my-tax-mobile-header__logo--mytax">
            <img :src="getLogoUrl" :alt="altTextSiteTitle + ' - ' + altTextAccessibility" />
          </div>
        </div>
      </li>
      <li class="my-tax-mobile-header__list-item">
        <Trap :disabled="!languageMenuIsVisible">
          <div
            class="mobile-menu-language"
            :aria-label="$trans('/accessibility/languagenavigation')"
            @keyup.27="toggleVisible"
            data-cy="mobile-menu-language"
          >
            <button
              v-show="!languageMenuIsVisible"
              id="mobile-menu-language-button"
              class="mobile-menu-language--button"
              data-cy="menu-language-toggle"
              aria-haspopup="true"
              aria-controls="mobile-menu-language-overlay"
              :lang="currentLanguage"
              :aria-expanded="languageMenuIsVisible ? 'true' : 'false'"
              @click="toggleVisible"
              @keyup.27="toggleVisible"
              ref="toggle"
            >
              <icon glyph="#icon-earth"></icon>
              <span class="mobile-menu-language-button__text">
                {{ currentLangCodeCapital() }}
              </span>
            </button>
            <button
              v-show="languageMenuIsVisible"
              id="mobile-menu-language-button"
              class="mobile-menu-language--close-button"
              data-cy="menu-language-toggle"
              aria-haspopup="true"
              aria-controls="mobile-menu-language-overlay"
              :aria-label="getButtonAriaLabelText"
              :aria-expanded="languageMenuIsVisible ? 'true' : 'false'"
              @click="toggleVisible"
              @keyup.27="toggleVisible"
              ref="toggle"
            >
              <span class="">
                <icon glyph="#icon-menu-close"></icon>
              </span>
              {{ this.$trans('/common/close') }}
            </button>
            <div
              class="mobile-menu-language--overlay"
              v-show="languageMenuIsVisible"
              id="mobile-menu-language-overlay"
              @click.self="toggleVisible"
              @keyup.27="toggleVisible"
              aria-labelledby="mobile-menu-language__overlay"
              role="region"
              v-cloak
            >
              <div class="container">
                <div class="row">
                  <div class="mobile-menu-language--overlay__wrapper">
                    <ul class="mobile-menu-language__list" v-if="hasLanguages">
                      <li
                        v-for="language of languages"
                        class="mobile-menu-language__list-item"
                        v-bind:key="language.DisplayName"
                      >
                        <a
                          class="mobile-menu-language__link"
                          :class="{ selected: language.Selected }"
                          :href="language.Url"
                          :lang="language.Name"
                          data-cy="mobile-menu-language-link"
                          v-html="language.DisplayName"
                        >
                        </a>
                        <icon glyph="#icon-vector"></icon>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Trap>
      </li>
      <li class="my-tax-mobile-header__list-item">
        <div class="my-tax-mobile-header__link">
          <a :href="startPageUrl">vero.fi</a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import _isNil from 'lodash/isNil';
import Trap from 'vue-focus-lock';

export default {
  name: 'MobileMenuLanguage',
  props: {
    languages: { type: Array, default: () => [] },
    startPageUrl: { type: String },
    logoUrl: { type: String },
    logoLanguage: { type: String },
    altTextSiteTitle: { type: String },
    altTextAccessibility: { type: String }
  },
  components: { Trap },
  data() {
    return {
      currentLanguage: window.site_locale,
      languageMenuIsVisible: false
    };
  },
  computed: {
    hasLanguages() {
      return !_isNil(this.languages) && this.languages.length > 0;
    },
    getButtonText() {
      return this.showCloseButton ? this.$trans('/common/close') : this.$trans('/common/menu');
    },
    getButtonAriaLabelText() {
      return this.showCloseButton
        ? this.$trans('/accessibility/close')
        : this.$trans('/accessibility/openmenumobile');
    },
    getLogoUrl() {
      return this.logoUrl
        ? this.logoUrl
        : '/static/img/logos/logo-omavero-' + 'this.logoLanguage' + '.svg';
    }
  },
  methods: {
    toggleVisible() {
      this.languageMenuIsVisible = !this.languageMenuIsVisible;
    },
    currentLangCodeCapital() {
      return this.currentLanguage.toUpperCase();
    }
  }
};
</script>
