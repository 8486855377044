<template>
  <Trap :disabled="!parentIsVisible">
    <div v-cloak>
      <div class="mobile-header__logo">
        <a
          v-if="!showCloseButton"
          class="mobile-header__logo--mytax"
          :href="startPageUrl"
          :aria-expanded="showCloseButton ? 'true' : 'false'"
          @click="toggleMobileMenu()"
          @keyup.27="toggleMobileMenu"
          ref="toggle"
        >
          <img :src="getLogoUrl" :alt="altTextSiteTitle + ' - ' + altTextAccessibility" />
        </a>
        <button
          v-if="showCloseButton"
          class="mobile-button menu-button hamburger"
          id="mobile-menu-button"
          aria-controls="mobile-menu"
          :aria-label="getButtonAriaLabelText"
          aria-haspopup="true"
          :aria-expanded="showCloseButton ? 'true' : 'false'"
          @click="toggleMobileMenu()"
          @keyup.27="toggleMobileMenu"
          ref="toggle"
        >
          <span class="hamburger-box">
            <icon glyph="#icon-menu-close"></icon>
          </span>
          {{ getButtonText }}
        </button>
      </div>
    </div>
  </Trap>
</template>

<script>
import _find from 'lodash/find';
import _first from 'lodash/first';
import _isNil from 'lodash/isNil';
import Trap from 'vue-focus-lock';

export default {
  name: 'MobileMenuMyTaxLogo',
  components: { Trap },
  props: {
    setVisibleMenu: { type: Function },
    parentIsVisible: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: false },
    startPageUrl: { type: String },
    logoUrl: { type: String },
    logoLanguage: { type: String },
    altTextSiteTitle: { type: String },
    altTextAccessibility: { type: String }
  },
  data() {
    return {
      isMobileMenuVisible: false
    };
  },
  computed: {
    getLogoUrl() {
      return this.logoUrl
        ? this.logoUrl
        : '/static/img/logos/logo-omavero-' + 'this.logoLanguage' + '.svg';
    },
    getGlyph() {
      return this.showCloseButton ? '#icon-menu-close' : '#icon-menu';
    },
    getButtonText() {
      return this.showCloseButton ? this.$trans('/common/close') : this.$trans('/common/menu');
    },
    getButtonAriaLabelText() {
      return this.showCloseButton
        ? this.$trans('/accessibility/close')
        : this.$trans('/accessibility/openmenumobile');
    }
  },
  methods: {
    toggleMobileMenu() {
      if (!this.parentIsVisible && this.showCloseButton) {
        this.setVisibleMenu(-1);
      } else {
        this.setVisibleMenu(1);
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
      }
    }
  }
};
</script>
