import isNil from 'lodash/isNil';
import jump from 'jump.js';

function getFilteredArray(arr) {
  const array = Object.keys(arr).map(k => arr[k]);
  return array.filter(function (field) {
    return field.name ? field.name.includes('__field') : false;
  });
}

function setValidationAttributes(arr) {
  arr.forEach(function (element) {
    // Clear all aria-invalid attributes
    element.removeAttribute('aria-invalid');
    // If not visible, do not set validation attributes
    if (element.offsetHeight === 0) {
      return;
    }
    const isValid = element.validity.valid && checkForValidationError(element);
    if (!isValid) {
      element.setAttribute('aria-invalid', 'true');
      return;
    }
    element.setAttribute('aria-invalid', 'false');
  });
}

function checkForValidationError(element) {
  const isCheckBoxOrRadio = element.type === 'radio' || element.type === 'checkbox';
  const validationErrorElement = isCheckBoxOrRadio
    ? element.parentNode.parentNode.nextElementSibling
    : element.nextElementSibling;
  return (
    validationErrorElement.matches('.Form__Element__ValidationError') &&
    validationErrorElement.offsetParent === null
  );
}

function validateForm(epiForm) {
  const validatedFields = epiForm.currentTarget;
  if (!isNil(validatedFields)) {
    const filteredArray = getFilteredArray(validatedFields);
    if (!isNil(filteredArray)) {
      setValidationAttributes(filteredArray);
    }
  }
}

function addTimeStamp() {
  const dt = new Date();
  const timestamp = document.createElement('p');
  timestamp.innerHTML = dt.toLocaleString();
  timestamp.setAttribute('data-cy', 'epiforms-timestamp');
  const successWrapperExists = setInterval(function () {
    const messageWrapper = document.querySelector('.Form__Success__Message');
    if (!isNil(messageWrapper)) {
      messageWrapper.appendChild(timestamp);
      clearInterval(successWrapperExists);
    }
    // Stop interval anyway after 10 seconds if element is not found
    if (new Date().getTime() - dt.getTime() > 10000) {
      clearInterval(successWrapperExists);
      return;
    }
  }, 100);
}

function focusToStartOfForm() {
  jump('#form-progress-jump-helper', {
    a11y: true,
    duration: 0
  });
}

function onEpiformsValidating(epiForm) {
  validateForm(epiForm);
}

function onEpiformsSubmitted() {
  addTimeStamp();
}

function onEpiformsSteps() {
  focusToStartOfForm();
}

export default {
  onEpiformsValidating,
  onEpiformsSubmitted,
  onEpiformsSteps
};
