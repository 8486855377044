<template>
  <div v-if="!feedbackGiven" v-cloak>
    <div class="feedback_input" v-show="!feedbackSent" v-cloak>
      <h3 class="feedback__heading">{{ translatedTitle }}</h3>
      <button @click="like()" :disabled="sendingFeedback" class="btn feedback__button">
        <icon glyph="#icon-thumbsup"></icon>
        <span>{{ translatedLikeButtonText }}</span>
      </button>
      <button
        @click="dislike()"
        :disabled="sendingFeedback"
        class="btn feedback__button feedback__button--dislike"
      >
        <icon glyph="#icon-thumbsup"></icon>
        <span>{{ translatedDislikeButtonText }}</span>
      </button>
    </div>
    <div v-if="feedbackSent" v-cloak>
      <h3 class="feedback__heading">{{ translatedTitle }}</h3>
      <dl>
        <dt>{{ translatedLikeButtonText }}</dt>
        <dd>
          <vue-circle
            :progress="getPercentLiked"
            :size="100"
            :reverse="false"
            line-cap="round"
            :fill="{ color: colorLiked }"
            empty-fill="rgba(0, 0, 0, .1)"
            :animation-start-value="0.0"
            :start-angle="0"
            insert-mode="append"
            :thickness="5"
            :show-percent="true"
          >
          </vue-circle>
        </dd>
        <dt>{{ translatedDislikeButtonText }}</dt>
        <dd>
          <vue-circle
            :progress="getPercentDisliked"
            :size="100"
            :reverse="false"
            line-cap="round"
            :fill="{ color: colorDisliked }"
            empty-fill="rgba(0, 0, 0, .1)"
            :animation-start-value="0.0"
            :start-angle="0"
            insert-mode="append"
            :thickness="5"
            :show-percent="true"
          >
          </vue-circle>
        </dd>
      </dl>
      <p class="sr-only" role="status">{{ getResultText }}</p>
    </div>

    <div v-if="feedbackError" v-cloak>
      <h3>{{ translatedErrorText }}</h3>
    </div>
  </div>
</template>

<script>
import feedbackAPI from 'api/FeedbackApi';
import Cookie from 'js-cookie';
import isNil from 'lodash/isNil';
import VueCircle from 'vue2-circle-progress';

const COOKIE_NAME = 'feedback_pageids';

export default {
  name: 'Feedback',
  components: {
    VueCircle
  },
  props: {
    pageId: {
      type: Number,
      default: -1
    },
    language: {
      type: String
    },
    translatedTitle: {
      type: String
    },
    translatedLikeButtonText: {
      type: String
    },
    translatedDislikeButtonText: {
      type: String
    },
    translatedResultText: {
      type: String
    },
    translatedErrorText: {
      type: String
    },
    colorLiked: {
      type: String
    },
    colorDisliked: {
      type: String
    }
  },
  data() {
    return {
      sendingFeedback: false,
      feedbackSent: false,
      feedbackSummary: null,
      feedbackGiven: false,
      feedbackGivenPageIdArray: [],
      feedbackError: false,
      feedbackType: null,
      countLiked: 0,
      countDisliked: 0,
      countTotal: 0
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.feedbackGivenPageIdArray = this.getCookie();
      this.feedbackGiven = this.isPageInArray();
    },
    addCookie() {
      this.feedbackGivenPageIdArray.push(this.pageId);
      Cookie.set(COOKIE_NAME, this.feedbackGivenPageIdArray);
    },
    getCookie() {
      return !isNil(Cookie.get(COOKIE_NAME)) ? JSON.parse(Cookie.get(COOKIE_NAME)) : [];
    },
    isPageInArray() {
      return this.feedbackGivenPageIdArray.indexOf(this.pageId) !== -1;
    },
    like() {
      this.feedbackType = 1;
      this.addFeedback();
    },
    dislike() {
      this.feedbackType = 2;
      this.addFeedback();
    },
    addFeedback() {
      if (this.feedbackSent) {
        return;
      }

      this.sendingFeedback = true;
      feedbackAPI
        .addFeedback(this.pageId, this.language, this.feedbackType)
        .then(data => {
          this.feedbackSummary = data;
          this.countLiked = this.feedbackSummary.likes;
          this.countDisliked = this.feedbackSummary.dislikes;
          this.countTotal = this.feedbackSummary.totalCount;
          this.feedbackSent = true;
          this.addCookie();
          this.sendingFeedback = false;
        })
        .catch(() => {
          this.sendingFeedback = false;
          this.feedbackError = true;
        });
    },
    calculatePercent(count) {
      const totalCount = this.countLiked + this.countDisliked;
      const percentage = (count / totalCount) * 100;
      return Math.round(percentage);
    }
  },
  computed: {
    getPercentLiked() {
      return this.calculatePercent(this.countLiked);
    },
    getPercentDisliked() {
      return this.calculatePercent(this.countDisliked);
    },
    getResultText() {
      return `${this.translatedResultText} ${this.translatedLikeButtonText} ${this.getPercentLiked}%, ${this.translatedDislikeButtonText} ${this.getPercentDisliked}%`;
    }
  }
};
</script>
