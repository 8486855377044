import API from 'utils/api';

/**
 *
 * @export
 * @param {string } query  - id
 * @param {string } language  - language
 * @returns
 */
export function getSearchSuggestions(
  searchSuggestionsUrl,
  query,
  language,
  rootFilter = [],
  count
) {
  return API.get(searchSuggestionsUrl, {
    params: {
      query,
      language,
      rootFilter,
      count
    }
  });
}

/**
 *
 *
 * @export
 * @param {any} term - Search term
 * @param {string} [language='fi'] - Language
 * @param {number} [page=1] - Current page
 * @param {number} [pagesize=10] - Number results per page
 * @param {number} [rootFilter] - root content ids where the search is targeted
 * @param {number} [sort] - Sort order of results
 * @param {array} [tag] - Filter by tags
 * @param {number} [year] - Filter by year
 * @param {number} [typeId] - Filter by content types
 * @param {boolean} [showAllVersions] = show all versions of files
 * @returns
 */
export function getSearchResults(
  searchFullUrl,
  query,
  language = 'fi',
  page = 1,
  pageSize = 10,
  rootFilter = [],
  sort,
  tag = [],
  year,
  typeId = [],
  showAllVersions = false
) {
  return API.get(searchFullUrl, {
    params: {
      query,
      language,
      page,
      pageSize,
      rootFilter,
      sort,
      tag,
      year,
      typeId,
      showAllVersions
    }
  });
}

/**
 *
 * @export
 * @param {string } query  - id
 * @param {string } language  - language
 * @returns
 */
const taxOfficeApiUrl = '/api/taxofficesearch';

export function getTaxOffices(language, municipality, section) {
  return API.get(taxOfficeApiUrl, {
    params: {
      language,
      municipality,
      section
    }
  });
}

const csvApiUrl = '/api/csv/results';

export function getCsvSearchResults(language, query, cid) {
  return API.get(csvApiUrl, {
    params: {
      language,
      query,
      cid
    }
  });
}

export default {
  getSearchSuggestions,
  getSearchResults,
  getTaxOffices,
  getCsvSearchResults
};
