import API from 'utils/api';
import _isNil from 'lodash/isNil';

const apiUrl = '/api/feedbacks';
const downloadApiUrl = '/api/feedbacks/download';

/**
 *
 * @export
 * @param {string } language  - language
 * @param {int} contentId  - contentId
 * @param {int} fromDate  - fromDate
 * @param {int} toDate  - toDate
 * @returns
 */
export function getFeedbacks(language, contentId, fromDate, toDate) {
  const params = {
    language
  };

  if (!_isNil(contentId) && contentId) {
    params.contentId = contentId;
  }

  if (!_isNil(fromDate) && fromDate) {
    params.fromDate = fromDate;
  }

  if (!_isNil(toDate) && toDate) {
    params.toDate = toDate;
  }
  return API.get(apiUrl, {
    params
  });
}

/**
 *
 * @export
 * @param {string } language  - language
 * @param {int} contentId  - contentId
 * @param {int} fromDate  - fromDate
 * @param {int} toDate  - toDate
 * @returns
 */
export function getDownloadUrl(language, contentId, fromDate, toDate) {
  let downloadUrl = downloadApiUrl;

  downloadUrl += `?language=${language}`;
  if (!_isNil(contentId) && contentId) {
    downloadUrl += `&contentId=${contentId}`;
  }

  if (!_isNil(fromDate) && fromDate) {
    downloadUrl += `&fromDate=${fromDate}`;
  }

  if (!_isNil(toDate) && toDate) {
    downloadUrl += `&toDate=${toDate}`;
  }
  return downloadUrl;
}

/**
 *
 * @export
 * @param {int} contentId  - id
 * @param {string} language  - language
 * @param {int} feedbackType  - feedbackType, 1 = like, 2 = dislike
 * @param {string} requestVerificationToken  - antiforgerytoken
 * @returns
 */
export function addFeedback(contentId, language, feedbackType) {
  /* eslint-disable-next-line */
  let requestVerificationToken = document.getElementsByName('__RequestVerificationToken')[0].value;

  const config = {
    headers: { 'x-csrf-token': requestVerificationToken } // todo move token fetching and header tweaking to api.js?
  };
  return API.post(
    apiUrl,
    {
      contentId,
      language,
      type: feedbackType,
      comment: null
    },
    config
  );
}
/**
 *
 * @export
 * @param {string} guid  - feedback guid
 * @param {string } comment  - feedback comment
 * @returns
 */
export function addComment(guid, comment) {
  return API.put(apiUrl, {
    guid,
    comment
  });
}

/**
 *
 * @export
 * @param {string } language  - language
 * @param {int} contentId  - contentId
 * @param {int} fromDate  - fromDate
 * @param {int} toDate  - toDate
 * @returns
 */
export function deleteFeedbacks(language, contentId, fromDate, toDate) {
  const params = {
    language
  };

  if (!_isNil(contentId) && contentId) {
    params.contentId = contentId;
  }

  if (!_isNil(fromDate) && fromDate) {
    params.fromDate = fromDate;
  }

  if (!_isNil(toDate) && toDate) {
    params.toDate = toDate;
  }
  return API.delete(apiUrl, {
    params
  });
}

export default {
  getFeedbacks,
  addFeedback,
  getDownloadUrl,
  deleteFeedbacks
};
