<script>
export default {
  inserted(el, binding, vnode) {
    vnode.context.$events.on('transformEvent', function (isVisible) {
      const cssClass = 'open';
      if (isVisible) {
        el.classList.add(cssClass);
        return;
      }
      el.classList.remove(cssClass);
    });
  }
};
</script>
