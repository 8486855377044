<template>
  <a
    :href="href"
    :class="cssClass"
    @click.prevent="scrollToAnchor"
    :aria-hidden="ariaHidden"
    :aria-label="ariaLabel"
  >
    {{ title }}
    <icon v-if="glyph" :glyph="glyph" />
  </a>
</template>

<script>
import jump from 'jump.js';

export default {
  name: 'AnchorLink',
  props: {
    href: {
      type: String,
      default: ''
    },
    cssClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    ariaHidden: {
      type: String,
      default: undefined
    },
    ariaLabel: {
      type: String,
      default: undefined
    },
    glyph: {
      type: String,
      default: ''
    }
  },
  methods: {
    scrollToAnchor() {
      if (this.href) {
        jump(this.href, {
          a11y: true
        });
      }
    }
  }
};
</script>
