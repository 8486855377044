/* Migrated content had accordions which markup cannot be changed, so make a custom implentation for migrated accordions */
export default function enableMigratedAccordions() {
  const accordions = document.querySelectorAll('[accordion-button]');
  if (accordions.length > 0) {
    [].forEach.call(accordions, el => {
      el.addEventListener('click', toggleAccordion);
    });
  }
}
function toggleAccordion(el) {
  el.preventDefault();
  const panelId =
    el.target.attributes['aria-controls'] !== undefined
      ? el.target.attributes['aria-controls'].value
      : '';
  if (panelId) {
    const panel = document.getElementById(panelId);
    const isPanelHidden = isHidden(panel);
    if (isPanelHidden) {
      panel.style.display = 'block';
      panel.previousElementSibling.classList.add('active');
    } else {
      panel.style.display = 'none';
      panel.previousElementSibling.classList.remove('active');
    }
  }
}
function isHidden(el) {
  return el.offsetParent === null;
}
// Accordion
//
// This is the Accordion component from migrated content
//
//<div class="accordion">
//    <div class="accordion__heading" role="heading">
//        <button id="heading_4bdf5496-ae9f-4d21-a789-05ca5935e759" aria-controls="panel_4bdf5496-ae9f-4d21-a789-05ca5935e759" tabindex="0" aria-expanded="false" accordion-button>
//        Arvonlisäverotuksen ulkopuolelle jäävä toiminta
//        <svg class="icon icon-chevron-down" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron-down"></use></svg>
//        </button>
//    </div>
//    <div class="accordion__panel" id="panel_4bdf5496-ae9f-4d21-a789-05ca5935e759" aria-labelledby="heading_4bdf5496-ae9f-4d21-a789-05ca5935e759">
//        <p>Arvonlisäverotuksen ulkopuolelle on jätetty joitakin arvonlisäverolaissa erikseen mainittuja toimintoja. Jos yritys myy ainoastaan näitä tavaroita tai palveluja:</p>
//        <ul>
//        <li>kiinteistöjen ja osakehuoneistojen myynti ja vuokraus</li>
//        <li>terveyden- ja sairaanhoito sekä sosiaalihuolto</li>
//        <li>arvonlisäverolaissa erikseen määritellyt koulutuspalvelut</li>
//        <li>rahoitus- ja vakuutuspalvelut</li>
//        <li>arvonlisäverolaissa erikseen määritellyt tekijänoikeuksista ja esiintymisistä saadut korvaukset</li>
//        <li>postin yleispalvelut.</li>
//        </ul>
//        <p>Kiinteistön omistaja tai haltija voi kuitenkin hakeutua arvonlisäverovelvolliseksi kiinteistön käyttöoikeuden luovuttamisesta eli vuokrauksesta, jos kiinteistöä tai sen osaa käytetään jatkuvasti arvonlisäverollisessa toiminnassa.</p>
//        <p><a href="#" target="_top">Lue lisää ohjeesta Kiinteistön käyttöoikeuden luovuttamisesta arvonlisäverovelvolliseksi hakeutuminen</a></p>
//    </div>
//</div>
